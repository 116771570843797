import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
  IonItem,
  IonRange,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItemDivider,
} from "@ionic/react";
import './styles/CarModal.scss';

import { closeOutline } from "ionicons/icons";

const CarDeleteModal = ({
  isModalShow,
  setModalShow,
  carData,
  filter,
  setFilter,
}) => {
  const [minMaxValuePrecio, setMinMaxValuePrecio] = useState({
    lower: 0,
    upper: 0,
  });
  const [minMaxRangePrecio, setminMaxRangePrecio] = useState({
    lower: 0,
    upper: 1000,
  });

  const [minMaxValueCV, setMinMaxValueCV] = useState({ lower: 0, upper: 0 });
  const [minMaxRangeCV, setminMaxRangeCV] = useState({ lower: 0, upper: 1000 });

  const [minMaxValueKm, setMinMaxValueKm] = useState({ lower: 0, upper: 0 });
  const [minMaxRangeKm, setminMaxRangeKm] = useState({ lower: 0, upper: 1000 });

  const [combustibleSelected, setCombustibleSelected] = useState('TODOS');
  const [combustibleOptions, setCombustibleOptions] = useState([]);

  const debounceValue = 500;

  useEffect(() => {
    if (carData && !filter) {
      let precioList = carData.flatMap((a) => a.precio);
      let cvsList = carData.flatMap((a) => parseInt(a.caracteristicas.cvs));
      let kmsList = carData.flatMap((a) => parseInt(a.caracteristicas.kms));
      let combustibles = carData.flatMap((a) => a.caracteristicas.combustible);

      setminMaxRangePrecio({
        lower: 0,
        upper: Math.max(...precioList),
      });
      setMinMaxValuePrecio({
        lower: 0,
        upper: Math.max(...precioList),
      });

      setminMaxRangeCV({
        lower: 0,
        upper: Math.max(...cvsList),
      });
      setMinMaxValueCV({
        lower: 0,
        upper: Math.max(...cvsList),
      });

      setminMaxRangeKm({
        lower: 0,
        upper: Math.max(...kmsList),
      });
      setMinMaxValueKm({
        lower: 0,
        upper: Math.max(...kmsList),
      });

      getCombustibleOptions(combustibles);
    }
  }, [carData, filter]);

  const getCombustibleOptions = (combustibles) => {
    let rawCombustibles = new Set(combustibles);
    setCombustibleOptions([...rawCombustibles]);
  };

  return (
    <IonModal className="car-filter-modal" isOpen={isModalShow} onDidDismiss={() => setModalShow(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Filtrar coches</IonTitle>
        </IonToolbar>
        <IonButtons slot="start">
          <IonButton color="dark" onClick={() => setModalShow(false)}>
            <IonIcon icon={closeOutline} size="large" />
          </IonButton>
        </IonButtons>
      </IonHeader>
      <IonContent className="ion-margin ion-paggind">
        <IonRow>
          <IonCol>
            <IonItemDivider>Rango precios</IonItemDivider>
            <IonItem>
              <IonRange
                id="dual-range"
                dualKnobs={true}
                pin={true}
                color="dark"
                min={minMaxRangePrecio.lower}
                max={minMaxRangePrecio.upper}
                debounce={debounceValue}
                value={minMaxValuePrecio}
                onIonChange={(data) => {
                  if (data.detail.value) {
                    if (
                      data.detail.value.lower !== minMaxValuePrecio.lower ||
                      data.detail.value.upper !== minMaxValuePrecio.upper
                    ) {
                      setFilter({
                        ...filter,
                        precio: {
                          lower: parseInt(data.detail.value.lower),
                          upper: parseInt(data.detail.value.upper),
                        },
                      });
                      setMinMaxValuePrecio({
                        lower: parseInt(data.detail.value.lower),
                        upper: parseInt(data.detail.value.upper),
                      });
                    }
                  }
                }}
              >
                <IonLabel slot="start">{minMaxRangePrecio.lower}</IonLabel>
                <IonLabel slot="end">{minMaxRangePrecio.upper}</IonLabel>
              </IonRange>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItemDivider>CV</IonItemDivider>
            <IonItem>
              <IonRange
                id="dual-range"
                dualKnobs={true}
                pin={true}
                color="dark"
                min={minMaxRangeCV.lower}
                max={minMaxRangeCV.upper}
                debounce={debounceValue}
                value={minMaxValueCV}
                onIonChange={(data) => {
                  if (data.detail.value) {
                    if (
                      data.detail.value.lower !== minMaxValueCV.lower ||
                      data.detail.value.upper !== minMaxValueCV.upper
                    ) {
                      setFilter({
                        ...filter,
                        cvs: {
                          lower: parseInt(data.detail.value.lower),
                          upper: parseInt(data.detail.value.upper),
                        },
                      });
                      setMinMaxValueCV({
                        lower: parseInt(data.detail.value.lower),
                        upper: parseInt(data.detail.value.upper),
                      });
                    }
                  }
                }}
              >
                <IonLabel slot="start">{minMaxValueCV.lower}</IonLabel>
                <IonLabel slot="end">{minMaxValueCV.upper}</IonLabel>
              </IonRange>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItemDivider>Km</IonItemDivider>
            <IonItem>
              <IonRange
                id="dual-range"
                dualKnobs={true}
                pin={true}
                color="dark"
                min={minMaxRangeKm.lower}
                max={minMaxRangeKm.upper}
                debounce={debounceValue}
                value={minMaxValueKm}
                onIonChange={(data) => {
                  if (data.detail.value) {
                    if (
                      data.detail.value.lower !== minMaxValueKm.lower ||
                      data.detail.value.upper !== minMaxValueKm.upper
                    ) {
                      setFilter({
                        ...filter,
                        kms: {
                          lower: parseInt(data.detail.value.lower),
                          upper: parseInt(data.detail.value.upper),
                        },
                      });
                      setMinMaxValueKm({
                        lower: parseInt(data.detail.value.lower),
                        upper: parseInt(data.detail.value.upper),
                      });
                    }
                  }
                }}
              >
                <IonLabel slot="start">{minMaxRangeKm.lower}</IonLabel>
                <IonLabel slot="end">{minMaxRangeKm.upper}</IonLabel>
              </IonRange>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Combustible:</IonLabel>
              <IonSelect
                className="car-filter-combust"
                value={combustibleSelected}
                okText="Aceptar"
                cancelText="Cerrar"
                debounce={debounceValue}
                onIonChange={(e) => {
                  setCombustibleSelected(e.detail.value);
                  setFilter({
                    ...filter,
                    combustible: e.detail.value,
                  });
                }}
              >
                {combustibleOptions.map((combustible, idx) => {
                  return (
                    <IonSelectOption value={combustible} key={idx}>
                      {combustible.toUpperCase()}
                    </IonSelectOption>
                  );
                })}
                <IonSelectOption value={null}>TODOS</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => setModalShow(false)}>
              Cerrar
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter></IonFooter>
    </IonModal>
  );
};

export default CarDeleteModal;
