import React from 'react';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';

import Home from '../pages/Home';
import Login from '../pages/Login';

import CarDetail from './CarDetail';
import CarDetailAuth from './CarDetailAuth';
import NotFound from './NotFound';
import NewCar from '../pages/NewCar';
import PrivateRoute from './PrivateRoute';

const Routes = () => {

    return (
        <IonReactRouter>
            {/* <Menu isAuthenticated={isAuthenticated} dispatch={dispatch} /> */}
            <IonSplitPane contentId="main">
                <IonRouterOutlet id="main">
                    <Route path="/" render={(props) => (
                        <Home {...props} />
                    )} exact />
                    <Route path="/:id" render={(props) => (
                        <CarDetail {...props} />
                    )} exact />
                    <Route path="/login" component={Login} exact />
                    <PrivateRoute path="/nuevo-coche" component={NewCar} />
                    <PrivateRoute path="/:id/edit" component={CarDetailAuth} />
                    <Route path="" component={NotFound} />
                    {/* <Redirect from="/" to="/coches" exact /> */}
                </IonRouterOutlet>
            </IonSplitPane>
        </IonReactRouter>
    )
}

export default Routes;