import React from 'react';
import { IonSelectOption } from '@ionic/react';

const CarBrands = ({ marcas }) => {
	return (
		Object.values(marcas).map((marca, idx) => {
			return (
				<IonSelectOption value={marca} key={idx}>
					{marca}
				</IonSelectOption>
			)
		})
	)
}

export default CarBrands;