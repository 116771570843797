import React from 'react';
import { IonApp } from '@ionic/react';

/* Redux */
import { connect } from 'react-redux';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './App.scss';
import Routes from './components/Routes';

/* Redux */
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import DevTools from './store/DevTools';
import { store, persistor } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { setLoading } from './store/user/actions';

const IonicApp = ({ isAuthenticated }) => {

  return (
    <IonApp>
      <Routes isAuthenticated={isAuthenticated}/>
    </IonApp>
  );
};

/* Connect to Redux */
function mapStateToProps(state) {
  return {
    isAuthenticated: state.userReducer.isAuthenticated,
    isLoading: state.userReducer.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLoading }, dispatch)
}

const ConnectedIonicApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(IonicApp);

class App extends React.Component {

  render() {

    return (
      // <Suspense fallback={<LoadingSpinner />}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ConnectedIonicApp />
            {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
          </PersistGate>
        </Provider>
      // </Suspense>
    )
  }
}

export default App;