import * as ActionTypes from './actions';
import { PURGE } from 'redux-persist';

const preLoadState = {
  isAuthenticated: false,
  isLoading: false,
  userInfo: {}
}

// Authenticate User Function
const authenticateUser = (info, state) => {
  return {
    userInfo: info.user,
    isAuthenticated: true,
    isLoading: state.isLoading
  }
}

const userReducer = (state = preLoadState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USER:
      return {
        ...authenticateUser(action.user, state),
      }
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case PURGE:
      return preLoadState;
    default:
      return state;
  }
}

export default userReducer;
