import React, { useState } from "react";
import { IonActionSheet } from "@ionic/react";

/* Plugins */
// import { CallNumber as callNumber } from "@ionic-native/call-number";
// import { SocialSharing } from "@ionic-native/social-sharing";
import shareFunctions from './functions/shareFunctions';

import ContactSendEmail from "./ContactSendEmail";

import {
  callOutline,
  mailOutline,
  closeOutline,
} from "ionicons/icons";

const CarActionSheet = ({ isActive, setIsActive }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IonActionSheet
        isOpen={isActive}
        onDidDismiss={setIsActive}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Enviar un mensaje",
            icon: mailOutline,
            handler: () => {
              setShowModal(!showModal);
            },
          },
          {
            text: "Llamar",
            icon: callOutline,
            handler: () => {
              shareFunctions.callNumber();
            },
          },
          {
            text: "Cancel",
            icon: closeOutline,
            role: "cancel",
            handler: () => {
              //console.log("Cancel clicked");
            },
          },
        ]}
      ></IonActionSheet>
      <ContactSendEmail isModalShow={showModal} setModalShow={setShowModal} />
    </>
  );
};

export default CarActionSheet;
