import ApiService from './ApiService';

export default class ApiCoches {
    constructor(){
        this.service = new ApiService();
    }

    sendContactInfo = (contactInfo) => {
        return this.service.post('/info/send', {
            'name': contactInfo.nombre,
            'email': contactInfo.email,
            'tel': contactInfo.tel,
            'text': contactInfo.mens,
            'ref': contactInfo.ref
        });
    }
}