import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Toasts {

  constructor() {
    toast.configure({
      autoClose: 4000,
      draggable: true,
      position: toast.POSITION.BOTTOM_CENTER
    })
  }

  defaultToast = (message, config) => {
    toast(message, config);
  }

  successToast = (message, config) => {
    toast.success(message, config);
  }

  errorToast = (message, config) => {
    toast.error(message, config);
  }

  warningToast = (message, config) => {
    toast.warn(message, config);
  }

  infoToast = (message, config) => {
    toast.info(message, config);
  }
}

export default Toasts;
