import React from 'react';
import { useHistory } from "react-router-dom";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonImg,
  IonTitle
} from '@ionic/react';

import CarSpinner from './CarSpinner';
import CarBadges from './CarBadges';

import './styles/ExploreContainer.scss';
import './styles/CarBadges.scss';

const CarList = ({ isAuthenticated, setModalShow, carData, doRefresh, filters }) => {

  const history = useHistory();

  const onClickDetail = (coche, isAuthenticated) => {
    if (isAuthenticated) {
      history.push({
        //pathname: `/coches/${coche.marca}-${coche.modelo}-${coche.caracteristicas.fecha_matriculacion}`,
        pathname: `/${coche.id}/edit`
      });
    } else {
      history.push({
        //pathname: `/coches/${coche.marca}-${coche.modelo}-${coche.caracteristicas.fecha_matriculacion}`,
        pathname: `/${coche.id}`
      });
    }
  }

  const renderCarList = (coches, isAdmin) => {
    // const slideOpts = {
    //   speed: 400,
    //   loop: true,
    //   zoom: {
    //     maxRatio: 1
    //   }
    // };

    let filteredCars = coches;

    if (filters) {
      //console.log(filters, ' filtro')

      if (filters.precio) {
        filteredCars = filteredCars.filter(coche => coche.precio >= filters.precio.lower && coche.precio <= filters.precio.upper);
      }
      if (filters.cvs) {
        filteredCars = filteredCars.filter(coche => parseInt(coche.caracteristicas.cvs) >= filters.cvs.lower && parseInt(coche.caracteristicas.cvs) <= filters.cvs.upper);
      }
      if (filters.kms) {
        filteredCars = filteredCars.filter(coche => parseInt(coche.caracteristicas.kms) >= filters.kms.lower && parseInt(coche.caracteristicas.kms) <= filters.kms.upper);
      }
      if (filters.combustible) {
        filteredCars = filteredCars.filter(coche => coche.caracteristicas.combustible === filters.combustible);
      }
    }
    //console.log(filteredCars, ' filtrados')

    // const renderCarImages = (coche) => {
    //   return coche.fotos.map((foto, idx) => (
    //     <IonSlide className="slider-image-car" key={idx} className="img_slider">
    //     </IonSlide>
    //   ));
    // };

    if (filteredCars.length > 0) {
      return filteredCars.map(coche => (
        <IonCol key={coche.id} size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3">
          <IonCard className="car-card-border" onClick={() => onClickDetail(coche, isAuthenticated)}>
            {
              coche.fotos.length > 0
              ?
              <IonImg 
                alt={coche.marca + coche.modelo} 
                src={`${coche.fotos[0].formats.small.url}`}
                height="150px" 
              />
              : null
            }
            <IonCardHeader>
              <IonCardTitle>{coche.marca.toUpperCase()} - {coche.modelo.toUpperCase()}</IonCardTitle>
              <IonCardSubtitle className="car-card-subtitle">{coche.precio} €</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <CarBadges detallesCoche={coche} />
              {
                isAdmin
                  ?
                  <IonButtons className="ion-margin-top">
                    <IonButton fill="solid" color="warning" onClick={() => onClickDetail(coche, isAuthenticated)}>Editar</IonButton>
                    <IonButton fill="solid" color="medium" onClick={() => setModalShow({ itemId: coche.id, active: true })}>Eliminar</IonButton>
                  </IonButtons>
                  :
                  null
                  // <IonButtons className="ion-margin-top">
                  //   <IonButton fill="solid" color="medium" onClick={() => onClickDetail(coche, isAuthenticated)}>Ver más</IonButton>
                  // </IonButtons>
              }
            </IonCardContent>

          </IonCard>
        </IonCol>
      ));
    } else {
        return (
          <IonCol className="ion-margin ion-text-center">
            <IonTitle color="danger">Vaya, parece que no hay coches disponibles en este momento.</IonTitle>
          </IonCol>
        )
    }
  }

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent
          refreshingSpinner="circular"
          pullingText="Pull to refresh"
        ></IonRefresherContent>
      </IonRefresher>
      <IonGrid>
        <IonRow className="car-list">
          {carData ? renderCarList(carData, isAuthenticated) : <CarSpinner />}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default CarList;