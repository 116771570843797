import React from 'react';
import { IonChip, IonLabel } from '@ionic/react';
import moment from 'moment';
import './styles/CarBadges.scss';

const CarBadges = ({ detallesCoche }) => {

  return (
    Object.keys(detallesCoche.caracteristicas).map((caract, indx) => {
      if (!caract.match('createdAt|updatedAt|id|__v')) {
        if (caract === "kms") {
          return <IonChip key={indx} className="datos-chip"><IonLabel>{detallesCoche.caracteristicas[caract]} kms</IonLabel></IonChip>
        } else if (caract === "cvs") {
          return <IonChip key={indx} className="datos-chip"><IonLabel>{detallesCoche.caracteristicas[caract]} cv</IonLabel></IonChip>
        } else if (caract === "puertas") {
          return <IonChip key={indx} className="datos-chip"><IonLabel>{detallesCoche.caracteristicas[caract]} pts</IonLabel></IonChip>
        } else if (caract === "fecha_matriculacion") {
          return <IonChip key={indx} className="datos-chip"><IonLabel>{moment(detallesCoche.caracteristicas[caract], "YYYY-MM-DD").format('YYYY')}</IonLabel></IonChip>
        }
        return (
          <IonChip key={indx} className="datos-chip">
            <IonLabel>
              { 
                detallesCoche.caracteristicas[caract].includes("automatico") ? detallesCoche.caracteristicas[caract].slice(0, 4) : detallesCoche.caracteristicas[caract]
              }
            </IonLabel>
          </IonChip>
        )
      } else {
        return null
      }
    })
  )
}

export default CarBadges;