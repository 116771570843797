import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import {
    IonContent,
    IonHeader,
    IonModal,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonTitle,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonTextarea
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import './styles/ContactSendEmail.scss';

import ApiInfo from '../api/ApiInfo';
import Toasts from './Toasts';

const toast = new Toasts();
const api = new ApiInfo();

const ContactSendEmail = ({ isModalShow, setModalShow }) => {

    const [contactInfo, setContactInfo] = useState({
        nombre: '',
        email: '',
        tel: '',
        mens: '',
        condt: false,
        ref: window.location.href
    });

    const sendEmail = async (contactInfo) => {
        if(contactInfo.condt){
        await api.sendContactInfo(contactInfo)
            .then(res => {
                toast.successToast(res.data);
            })
            setModalShow(false);
        } else {
            toast.errorToast('Por favor, acepta los términos.');
        }
    }

    const onSubmitMail = (event) => {
        event.preventDefault();
        sendEmail(contactInfo);
    }

    const cleanModalInputs = () => {
        setContactInfo({});
        setModalShow(false);
    }

    return (
        <IonModal className="car-share-modal" isOpen={isModalShow} onDidDismiss={() => cleanModalInputs()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Contacta con nosotros</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="dark" onClick={() => setModalShow(false)}>
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <form onSubmit={onSubmitMail}>
                    <IonItem>
                        <IonLabel position="floating">Nombre</IonLabel>
                        <IonInput name="form-name" required type="text" value={contactInfo.nombre} onIonChange={(e) => setContactInfo({...contactInfo, nombre: e.detail.value})}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput name="form-email" required type="email" value={contactInfo.email} onIonChange={(e) => setContactInfo({...contactInfo, email: e.detail.value})}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Teléfono</IonLabel>
                        <IonInput name="form-phone" required type="tel" value={contactInfo.tel} onIonChange={(e) => setContactInfo({...contactInfo, tel: e.detail.value})}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Mensaje</IonLabel>
                        <IonTextarea name="form-message" required type="text" value={contactInfo.mens} onIonChange={(e) => setContactInfo({...contactInfo, mens: e.detail.value})} placeholder="Escribe aquí tu mensaje"></IonTextarea>
                    </IonItem>
                    <IonItem className="form-check-item" lines="none">
                        <IonCheckbox required name="form-check-condition" value={contactInfo.condt} onIonChange={(e) => setContactInfo({...contactInfo, condt: !contactInfo.condt})} />
                        <IonLabel className="form-check-conditions">Acepto las condiciones de uso y política de privacidad.</IonLabel>
                    </IonItem>
                    <IonButton className="ion-margin" fill="solid" type="submit" expand="full">Enviar</IonButton>
                </form>
            </IonContent>
        </IonModal>
    );
}

export default withRouter(ContactSendEmail);
