import React from 'react'
import Lottie from 'react-lottie';
import animationData from './animation/carAnimation.json';

const CarSpinner = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie options={defaultOptions} width="800px" isClickToPauseDisabled={true} eventListeners={defaultOptions.eventListener} />
    );
};

export default CarSpinner;
