class shareFunctions {

    static callNumber = () => {
        window.location.href = "tel:+34630403548";
        // callNumber.callNumber("+34630403548", true)
        // .then(res => console.log('Hemos llamado!', res))
        // .cath(err => console.log('Error llamando', err));
    };

    static shareCar = (car_link) => {
        const emojis = ["🚗", "🔧", "❤️"];
        const text_message = `Echa un vistazo a este coche ${emojis[0]}:\n\nTodos nuestro vehículos revisados y con la mejor garantía\n\nCompartido desde Talleres Goferan ${emojis[1]} con ${emojis[2]}\n\n`;
        const options = {
            title: "Talleres Goferan, comparte un coche!",
            text: text_message,
            url: car_link,
        };

        navigator.share(options);

        // return <ShareButton title="Talleres Goferan, comparte un coche!" text={text_message} url={car_link} />
        // SocialSharing.shareWithOptions(options)
        //   .then((response) => {
        //     //console.log(response);
        //   })
        //   .catch((response) => {
        //     //console.log(response);
        //   });
    }
}

export default shareFunctions;
