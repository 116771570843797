import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonButton
} from '@ionic/react';

import './styles/NotFound.scss';

const NotFound = () => {

    return (
        <IonGrid>
            <IonRow class="login-img-row ion-align-items-center">
                <IonCol size="12" sizeMd="3" class="ion-margin-end ion-align-items-center">
                    <IonImg className="login-logo" src="/assets/img/404.png"></IonImg>
                </IonCol>
                <IonCol size="12" sizeMd="8">
                    <IonText size="large">
                        <h1 className="notfound-header">Vaya!</h1>
                        <h2 className="notfound-second-header">No hemos podido encontrar el coche que buscas.</h2>
                        <h3 className="notfound-second-header">Código de error: 404</h3>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonRow class="ion-align-items-center">
                <IonCol size="12" class="ion-text-center">
                    <IonButton href='/' class="ion-margin-top">Volver al inicio</IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default NotFound;
