import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import userAuthReducer from './user/authReducer';
import cochesReducer from './coches/reducer';

const rootReducer = combineReducers({
    userReducer: userReducer,
    userAuthReducer: userAuthReducer,
    cochesReducer: cochesReducer
});

export default rootReducer;
