import ApiService from './ApiService';

export default class ApiCoches {
    constructor(){
        this.service = new ApiService();
    }

    getUser = (email, password) => {
        return this.service.post('/auth/local', {
            'identifier': email,
            'password': password
        });
    }
}