import axios from 'axios';

export default class ApiService {
    constructor(timeout) {
        // Save API URL from .env variable
        const API_URL = process.env.REACT_APP_API_URL;
        const requestTimeout = timeout;

        // Instance Axios service
        this.service = axios.create({
            baseURL: API_URL,
            timeout: requestTimeout,
        });

        // Use methods for interceptors in all requests
        this.service.interceptors.request.use(this.handleAccessToken, this.handleError);
        this.service.interceptors.response.use(this.handleSuccess, this.handleError);

    }

    // Request Interceptor
    handleAccessToken = (config) => {
      // Do something before request is sent
      const user_info = JSON.parse(localStorage.getItem('persist:root'));
    
      if (localStorage.length > 0 && Object.keys(JSON.parse(user_info['userAuthReducer'])).length !== 0) {
        const jwt_token = JSON.parse(user_info['userAuthReducer'])['jwt_token'];
        
        // Set Access Token
        config.headers['Authorization'] = 'Bearer ' + jwt_token;
      }
    
      return config;
    }

    // Response Interceptor's
    handleSuccess = (response) => {
        return response;
    }

    handleError = async (error) => {

        if (process.env.NODE_ENV === 'development') console.log(error);

        return Promise.reject(error)
    }

    get(path, config) {
        return this.service.get(path, config);
    }

    post(path, payload, config) {
        return this.service.post(path, payload, config);
    }

    put(path, payload, config) {
        return this.service.put(path, payload, config);
    }

    delete(path, config) {
        return this.service.delete(path, config);
    }

    options(path, payload, config) {
        return this.service.options(path, payload, config);
    }

    head(path, payload, config) {
        return this.service.head(path, payload, config);
    }

    patch(path, payload, config) {
        return this.service.patch(path, payload, config);
    }
}