import ApiCoches from '../../api/ApiCoches';
import Toast from '../../components/Toasts';

const api = new ApiCoches();
const toast = new Toast();

// Types Actions
export const LOAD_TOTAL_COCHES = 'LOAD_TOTAL_COCHES';
export const LOAD_COCHES_MARCAS = 'LOAD_COCHES_MARCAS';
export const LOAD_COCHES = 'LOAD_COCHES';
export const CREATE_COCHE = 'CREATE_COCHE';
export const EDIT_COCHE = 'EDIT_COCHE';
export const DELETE_COCHE = 'DELETE_COCHE';

// Actions
const actionTotalCoches = (type, total) => {
  return {
    type: type,
    total: total
  }
}

// const actionCochesMarcas = (type, marcas) => {
//   return {
//     type: type,
//     marcas: marcas
//   }
// }

const actionCoches = (type, coches) => {
  return {
    type: type,
    coches: coches
  }
}

const detailActionCoche = (type, coche) => {
  return {
    type: type,
    coche: coche
  }
}

const deleteActionCoche = (type, coche_id) => {
  return {
    type: type,
    coche_id: coche_id
  }
}

// Functions
export const createCoche = (coche) => {
  return (dispatch) => {
    api.postCoche(coche)
      .then(response => {
        dispatch(detailActionCoche(CREATE_COCHE, response.data));
        toast.successToast('Coche subido correctamente.');
      }).catch((error) => {
        toast.errorToast('Error al subir el coche.');
      });
  }
}

export const listCoches = (blocks) => {
  return (dispatch) => {
    api.getCoches(blocks)
      .then((response) => {
        dispatch(actionCoches(LOAD_COCHES, response.data));
      });
  }
}

export const listTotalCoches = () => {
  return (dispatch) => {
    api.getTotalCoches()
      .then((response) => {
        if(response.data === 0){
          toast.errorToast('Vaya, parece que no hay coches disponibles :(')
        } else {
          dispatch(actionTotalCoches(LOAD_TOTAL_COCHES, response.data));
        }
      })
  }
}

export const listCochesMarcas = () => {
  return (dispatch) => {
    return api.getCochesMarcas()
      .then((response) => {
        let marcasList = response.data.marcas.split(",");
        return marcasList;
      })
  }
}

export const detailCoche = (coche_id) => {
  return (dispatch) => {
    return api.getCocheDetail(coche_id)
    .then(response => {
      return response.data[0];
    }).catch((error) => {
      return null;
    });
  }
}

export const updateCoche = (coche, listaFotos) => {
  return (dispatch) => {
    api.updateCoche(coche)
      .then(response => {
        // listaFotos.forEach((foto_id) => {
        //   api.deleteFoto(foto_id);
        // });
        dispatch(detailActionCoche(EDIT_COCHE, response.data));
        toast.successToast('Coche actualizado correctamente.');
      }).catch((error) => {
        toast.errorToast('Error al actualizar el coche.');
      });
  }
}

export const deleteCoche = (coche_id) => {
  return (dispatch) => {
    api.deleteCoche(coche_id)
      .then(() => {
        dispatch(deleteActionCoche(DELETE_COCHE, coche_id));
        toast.successToast('Coche eliminado correctamente.');
      }).catch((error) => {
        toast.errorToast('Error al eliminar el coche.');
      });
  }
}