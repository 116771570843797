import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from "@ionic/react";

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { detailCoche, updateCoche, listCochesMarcas } from '../store/coches/actions';

import "./styles/CarDetail.scss";

import CarFotoEditor from "./CarFotoEditor";
import CarBrands from "./CarBrands";
import NotFound from "./NotFound";

const CarDetailAuth = ({ coches, detailCoche, updateCoche, listCochesMarcas }) => {
  const { id } = useParams();

  const [marcasCoches, setMarcasCoches] = useState([]);
  const [editDatosCoche, setEditCoche] = useState({
    caracteristicas: {
      transmision: "",
      combustible: "",
      cvs: 0,
      fecha_matriculacion: "",
      kms: 0,
      puertas: 0,
    },
    fotos: [],
    precio: 0,
    marca: "",
    modelo: "",
    reservado: false
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const getCarBrands = async () => {
    const marcas = await listCochesMarcas();
    setMarcasCoches(marcas);
  }

  useEffect(() => {

    if (firstLoad) {
      const getCarDetail = async (coche_id) => {
        const coche = await detailCoche(coche_id);
        setEditCoche(coche);
      };

      if (coches.length > 0) {
        const coche = coches.filter((coche) => {
          return coche.id === id;
        })[0];
        setEditCoche(coche);
      } else {
        getCarDetail(id);
      }

      if(marcasCoches.length <= 0){
        getCarBrands();
      }
      setFirstLoad(false);
    }

    // eslint-disable-next-line
  }, [editDatosCoche]);

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    updateCoche(editDatosCoche);
  };

  //console.log(editDatosCoche);

  return (
    editDatosCoche ?
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            {editDatosCoche.marca} - {editDatosCoche.modelo}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={onSubmitLogin}>
          <IonGrid className="car-detail-grid ion-no-padding">
            <IonRow>
              <IonCol className="car-auth-form">
                <IonCol>
                  <IonItem>
                    <IonLabel color="danger" position="floating">Marca</IonLabel>
                    <IonSelect
                      required
                      value={editDatosCoche.marca}
                      okText="Aceptar"
                      cancelText="Cerrar"
                      onIonChange={(e) => {
                        setEditCoche({
                          ...editDatosCoche,
                          marca: e.detail.value
                        });
                      }}
                    >
                     { marcasCoches ? <CarBrands marcas={marcasCoches} /> : null}
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel color="danger" position="floating">Modelo</IonLabel>
                    <IonInput
                      required
                      type="text"
                      value={editDatosCoche.modelo.toUpperCase()}
                      onIonInput={(e) =>
                        setEditCoche({
                          ...editDatosCoche,
                          modelo: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </IonItem>
                </IonCol>
                <IonRow>
                  <IonCol size="12" sizeMd="6">
                    <IonItem>
                      <IonLabel color="danger" position="floating">Año</IonLabel>
                      <IonInput
                        required
                        value={editDatosCoche.caracteristicas.fecha_matriculacion.substring(
                          0,
                          10
                        )}
                        type="date"
                        onIonInput={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              fecha_matriculacion: e.target.value,
                            },
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="danger" position="floating">Cvs</IonLabel>
                      <IonInput
                        required
                        type="number"
                        min={0}
                        max={1000}
                        value={editDatosCoche.caracteristicas.cvs}
                        onIonInput={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              cvs: e.target.value,
                            },
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="danger" position="floating">Kms</IonLabel>
                      <IonInput
                        required
                        type="number"
                        min={0}
                        max={1500000}
                        value={editDatosCoche.caracteristicas.kms}
                        onIonInput={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              kms: e.target.value,
                            },
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="danger" position="floating">Puertas</IonLabel>
                      <IonInput
                        required
                        type="number"
                        min={0}
                        max={10}
                        value={editDatosCoche.caracteristicas.puertas}
                        onIonInput={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              puertas: e.target.value,
                            },
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="6">
                    <IonItem>
                      <IonLabel color="danger" position="floating">Transm</IonLabel>
                      <IonSelect
                        required
                        value={editDatosCoche.caracteristicas.transmision}
                        placeholder="Selecciona"
                        onIonChange={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              transmision: e.detail.value,
                            },
                          })
                        }
                      >
                        <IonSelectOption value="automatico">Automático</IonSelectOption>
                        <IonSelectOption value="manual">Manual</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" sizeMd="6">
                    <IonItem>
                      <IonLabel color="danger" position="floating">Combust</IonLabel>
                      <IonSelect
                        required
                        value={editDatosCoche.caracteristicas.combustible}
                        onIonChange={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            caracteristicas: {
                              ...editDatosCoche.caracteristicas,
                              combustible: e.detail.value,
                            },
                          })
                        }
                        placeholder="Selecciona"
                      >
                        <IonSelectOption value="diesel">Diesel</IonSelectOption>
                        <IonSelectOption value="gasolina">Gasolina</IonSelectOption>
                        <IonSelectOption value="glp">GLP</IonSelectOption>
                        <IonSelectOption value="electrico">Eléctrico</IonSelectOption>
                        <IonSelectOption value="hibrido">Híbrido</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="danger" position="floating">Precio</IonLabel>
                      <IonInput
                        required
                        type="number"
                        min={0}
                        max={100000}
                        value={editDatosCoche.precio}
                        onIonInput={(e) =>
                          setEditCoche({
                            ...editDatosCoche,
                            precio: e.target.value
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel color="danger" position="floating">Reservado</IonLabel>
                      <IonToggle
                        required
                        checked={editDatosCoche.reservado}
                        onIonChange={e => setEditCoche({
                          ...editDatosCoche,
                          reservado: e.detail.checked
                        })} />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonItem lines="none" className="car-description-item">
                  <IonLabel
                    className="car-description"
                    color="danger"
                    position="floating"
                  >Descripción</IonLabel>
                  <IonTextarea
                    required
                    value={editDatosCoche.descripcion}
                    rows={6}
                    onIonInput={(e) =>
                      setEditCoche({
                        ...editDatosCoche,
                        descripcion: e.target.value
                      })
                    }
                  ></IonTextarea>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-top">
              <IonCol size="12">
                <IonButton
                  color="medium"
                  expand="block"
                  onClick={() => setModalShow(true)}
                >
                  Editar imágenes
                </IonButton>
              </IonCol>
              <IonCol size="12">
                <IonButton
                  type="submit"
                  expand="block"
                  color="danger"
                >
                  Actualizar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        {
          editDatosCoche.fotos.length > 0
            ?
            <CarFotoEditor
              isModalShow={modalShow}
              setModalShow={setModalShow}
              editDatosCoche={editDatosCoche}
              setEditCoche={setEditCoche}
            />
            : null}
      </IonContent>
    </IonPage>
    :
    <IonPage>
    <IonHeader>
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref="/" />
      </IonButtons>
      <IonTitle>No encontrado</IonTitle>
    </IonToolbar>
    </IonHeader>
    <IonContent>
      <NotFound/>
    </IonContent>
    </IonPage>
  );
};

function mapStateToProps(state) {
  return {
    coches: state.cochesReducer.coches
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ detailCoche, updateCoche, listCochesMarcas }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CarDetailAuth));
