import React, { useState } from 'react';
import {
    IonContent,
    IonHeader,
    IonModal,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonThumbnail,
    IonImg,
    IonItem,
    IonReorderGroup,
    IonReorder,
    IonText,
    IonRow,
    IonCol,
    IonLabel,
    IonFooter,
    IonProgressBar
} from '@ionic/react';
import ApiService from '../api/ApiService';
import ApiCoches from '../api/ApiCoches';

import { cameraOutline, closeOutline, trashBinOutline } from 'ionicons/icons';
import './styles/CarFotoEditor.scss';

const service = new ApiService();
const api = new ApiCoches();

const CarFotoEditor = ({ isModalShow, setModalShow, editDatosCoche, setEditCoche }) => {

    const [progress, setProgress] = useState({
        active: false,
        number: 0
    });

    const postCocheFotos = async (car_fotos) => {
        return await service.post('/upload', car_fotos, {
            headers: {
                    'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                let progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                //console.log(progress)
                setProgress({
                    active: true,
                    number: progress
                })
            }
            }).then(resp => resp.data)
    }

    const doReorder = (e) => {
        let updatedFotosArray = e.detail.complete(editDatosCoche.fotos);
        setEditCoche({...editDatosCoche, fotos: updatedFotosArray});
    }

    const deleteFromGallery = (id) => {
        let updatedFotosArray = editDatosCoche.fotos.filter(item => item.id !== id);
        setEditCoche({...editDatosCoche, fotos: updatedFotosArray});
        api.deleteFoto(id);
    }

    const uploadImage = async (e) => {
        let newFotos = e;
        let formData = new FormData();
        for (let index = 0; index < newFotos.length; index++) {
            formData.append('files', newFotos[index]);
        }
        const resp = await postCocheFotos(formData);
        setEditCoche({...editDatosCoche, fotos: editDatosCoche.fotos.concat(resp)});
    }

    const renderThumbImage = (arrayFotos) => {
        return arrayFotos.map((img, idx) => (
            <IonItem key={idx} id={img.id + '-item'} className="ion-text-center">
                <IonThumbnail slot="start">
                    <IonImg src={`${img.formats.thumbnail.url}`} />
                </IonThumbnail>
                <IonReorder slot="start" />
                <IonButton 
                    disabled={
                        editDatosCoche.fotos.length <= 1 && !window.location.pathname.includes('nuevo-coche')
                        ? true : false} 
                    onClick={() => deleteFromGallery(img._id)} 
                    fill="clear" 
                    size="large" 
                    slot="end"
                >
                    <IonIcon icon={trashBinOutline} />
                </IonButton>
            </IonItem>
        ));
    };

    const closeAndRestart = () => {
        setProgress({
            active: false,
            progress: 0
        });
        setModalShow(false);
    }

    return (
        <IonModal
            className="car-foto-editor-modal"
            isOpen={isModalShow}
            onDidDismiss={() => closeAndRestart()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Editar fotos</IonTitle>
                    <IonButtons slot="start">
                        <IonButton color="danger" onClick={() => closeAndRestart()}>
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                    </IonButtons>
                    {/* <IonButtons slot="end">
                        <IonButton color="danger" onClick={() => setReorderFotos(!reorderFotos)}>
                            <IonLabel>Ordenar</IonLabel>
                        </IonButton>
                    </IonButtons> */}
                </IonToolbar>
                <IonButtons slot="start">
                    <IonButton color="dark" onClick={() => closeAndRestart()}>
                        <IonIcon icon={closeOutline} size="large" />
                    </IonButton>
                </IonButtons>
            </IonHeader>
            <IonContent className="ion-margin ion-padding ion-text-center">
                <IonRow>
                    <IonCol>
                        {editDatosCoche.fotos.length > 0
                            ?
                            <IonReorderGroup disabled={false} onIonItemReorder={(e) => doReorder(e)}>
                                {renderThumbImage(editDatosCoche.fotos)}
                            </IonReorderGroup>
                            : <IonText>Este coche no tiene fotos seleccionadas.</IonText>
                        }
                    </IonCol>
                </IonRow>
            </IonContent>
            <IonFooter>
                {
                    progress.active ?
                    <IonRow className="ion-text-center ion-margin">
                        <IonCol>
                            <IonTitle className="ion-margin-bottom">Progreso {progress.number}%</IonTitle>
                            <IonProgressBar color="danger" value={(progress.number/100)} />
                        </IonCol>
                    </IonRow>
                    : null
                }
                <IonRow className="ion-text-center">
                    <IonCol>
                        <IonButton color="medium" expand="full" onClick={() => closeAndRestart()}>
                            <IonLabel>Aceptar</IonLabel>
                        </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton expand="full">
                            <input
                                accept="image/*"
                                id="carFotoUpload"
                                type="file"
                                multiple={true}
                                name="files"
                                onChange={(e) => uploadImage(e.target.files)}
                            />
                            <IonLabel className="ion-margin-end" for="carFotoUpload">Subir fotos nuevas</IonLabel>
                            <IonIcon size="large" icon={cameraOutline} />
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonModal>
    )
}


export default CarFotoEditor;
