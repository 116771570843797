import React, { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonIcon,
  IonInput,
  IonButton,
  IonImg
} from '@ionic/react';
import { withRouter, useHistory } from "react-router-dom";
/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logIn, setLoading } from '../store/user/actions';

import './styles/Login.scss';
import { person, key, eyeOutline, eyeOffOutline } from 'ionicons/icons';

const Login = ({ logIn }) => {

  const history = useHistory();

  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    await logIn(loginDetails, history);
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmitLogin(e);
    }
  } 

  return (
    <IonPage className="login-page">
      <IonContent>
        <IonGrid>
          <IonRow class="login-img-row">
            <IonCol align-self-center>
              <IonImg className="login-logo" src="/assets/img/logo.png" with="80%"></IonImg>
            </IonCol>
          </IonRow>
          <IonRow className="login-form-row">
            <IonCol align-self-center>
              <form onSubmit={onSubmitLogin} >
                <IonItem className="item-form">
                  <IonIcon className="ion-icon-form" slot="start" icon={person} />
                  <IonInput
                    value={loginDetails.email}
                    name="email"
                    placeholder="ejemplo@mail.com"
                    type="email"
                    onIonInput={(e) => setLoginDetails({ ...loginDetails, email: e.target.value })}
                    onKeyDown={(e) => _handleKeyDown(e)}
                    required />
                </IonItem>
                <IonItem className="item-form">
                  <IonIcon className="ion-icon-form" slot="start" icon={key} />
                  <IonInput
                    value={loginDetails.password}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onIonInput={(e) => setLoginDetails({ ...loginDetails, password: e.target.value })}
                    onKeyDown={(e) => _handleKeyDown(e)}
                    required />
                  <IonIcon className="ion-icon-form" slot="end" icon={showPassword ? eyeOutline : eyeOffOutline} onClick={() => setShowPassword(!showPassword)} />
                </IonItem>
                <IonButton className="send-button" type="submit" expand="block">Iniciar Sesión</IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

function mapStateToProps(state) {
  return {
    isLoading: state.userReducer.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logIn, setLoading }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login));
