import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

// Redux Persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    whitelist: [
        'userReducer',
        'userAuthReducer'
    ],
}

const preloadedState = {};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
    persistedReducer,
    preloadedState,
    applyMiddleware(thunk)
)
const persistor = persistStore(store);

export { store, persistor };
