import ApiService from './ApiService';

export default class ApiCoches {
    constructor() {
        this.service = new ApiService();
    }

    getTotalCoches = () => {
        return this.service.get('/coches/count')
    }

    getCoches = (blocks) => {
        return this.service.get('/coches', {
            params: {
                _limit: blocks
            }
        });
    }

    getCocheImages = async (img) => {
        return await this.service.get(`${img.url}`,{responseType: 'blob'}).then(resp => { return resp.data });
    }

    getCocheDetail = (coche_id) => {
        return this.service.get('/coches', {
            params: {
                id: coche_id
            }
        });
    }

    getCochesMarcas = () => {
        return this.service.get('/coches-marcas');
    }

    postCoche = (coche) => {
        return this.service.post('/coches', coche);
    }

    updateCoche = (coche) => {
        return this.service.put(`/coches/${coche.id}`, coche)
    }

    deleteCoche = (coche_id) => {
        return this.service.delete(`/coches/${coche_id}`)
    }

    deleteFoto = (foto_id) => {
        return this.service.delete(`/upload/files/${foto_id}`)
    }
}