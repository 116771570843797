import React from 'react';

/* Redux */
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {

    return (
        <Route {...rest} render={props => (
            isAuthenticated === true
                ? <Component {...props} />
                : <Redirect to="/" />
        )} />
    )
};

function mapStateToProps(state) {
    return {
      isAuthenticated: state.userReducer.isAuthenticated,
    }
}
  
  export default connect(mapStateToProps)(PrivateRoute);