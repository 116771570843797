import ApiUser from '../../api/ApiLogin';
import Toast from '../../components/Toasts';
import { PURGE } from 'redux-persist';

const api = new ApiUser();
const toast = new Toast();

// Types Actions
export const LOAD_USER = 'LOAD_USER';
export const LOAD_AUTH_USER = 'LOAD_AUTH_USER';
export const SET_LOADING = 'SET_LOADING';

// Actions
const actionUser = (type, user) => {
  return {
    type: type,
    user: user
  }
}

export const logOut = () => {
  return async (dispatch) => {
    await dispatch({
      type: PURGE,
      result: () => null
    });
  }
}

export const logIn = (loginDetails, history) => {
  return async (dispatch) => {
    //dispatch({type: SET_LOADING, isLoading: true});
    await api.getUser(loginDetails.email, loginDetails.password)
      .then(async (response) => {
        toast.successToast('Inicio de sesión correcto', {autoClose: 1500});
        await dispatch(actionUser(LOAD_AUTH_USER, response.data));
        await dispatch(actionUser(LOAD_USER, response.data));
        window.location.href = "/";
      }).catch((error) => {
        toast.errorToast('Hay un problema al iniciar la sesión. Revisa las credenciales.');
      });
  }
}

export const setLoading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      isLoading: isLoading
    });
  };
}