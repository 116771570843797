import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonSearchbar,
  IonRow,
  IonGrid,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
  IonButton,
  isPlatform
} from '@ionic/react';
import { useParams, useHistory } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { listCoches, listTotalCoches, listCochesMarcas } from '../store/coches/actions';
import { logOut } from '../store/user/actions';

import CarSpinner from '../components/CarSpinner';
import CarList from '../components/CarList';
import DeleteModal from '../components/DeleteModal';
import CarFilterModal from '../components/CarFilterModal';

import './styles/Home.scss';
import { addOutline, logOutOutline, optionsOutline } from 'ionicons/icons';

const Home = ({ isAuthenticated, listCoches, listTotalCoches, logOut, coches, totalCoches, isLoading }) => {
  const { name } = useParams();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(null);
  const [modalShowFilter, setModalShowFilter] = useState(false);
  const [modalShow, setModalShow] = useState({
    active: false,
    itemId: null
  });
  const [blocks, setBlocks] = useState(8);
  const [clickToLogin, setClickToLogin] = useState(0);

  useIonViewWillEnter(() => {
    listTotalCoches();
  })

  useEffect(() => {
    setSearchBarEventListener();
    listCoches(blocks);
    // eslint-disable-next-line
  }, [blocks]);

  const clickLogin = () => {
    setClickToLogin(clickToLogin + 1);
    if (clickToLogin === 7) {
      setClickToLogin(0);
      history.push('/login');
    }
  }

  const doRefresh = async (event) => {
    await listCoches();
    await event.detail.complete();
  }

  const onHandleInputSearchBar = (event) => {
    const items = Array.from(document.querySelector('ion-row.car-list').children);

    requestAnimationFrame(() => {
      const query = event.target.value.toLowerCase();
      console.log(query)
      items.forEach(item => {
        const item_children = item.children[0].children[0].children[0].textContent;
        const shouldShow = item_children.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

  const setSearchBarEventListener = () => {
    // Search Bar Listener
    const searchbar = document.querySelector('ion-searchbar');
    searchbar.addEventListener('ionInput', onHandleInputSearchBar);
  }

  const onClickCreate = () => {
    history.push({
      pathname: `/nuevo-coche`
    })
  }

  const createOrFilterButton = (isAuthenticated) => {
    if (!isAuthenticated) {
      return (
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerDirection="forward" onClick={() => setModalShowFilter(true)} color="danger">
            <IonIcon icon={optionsOutline} />
          </IonFabButton>
        </IonFab>
      )
    }else if(isAuthenticated){
      return (
        <IonFab className="car-filter-fab" vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerDirection="forward" onClick={() => onClickCreate()} color="danger">
            <IonIcon icon={addOutline} />
          </IonFabButton>
        </IonFab>
      )
    }
  }

  const iconLogOut = (isAuth) => {
    if (isAuth) {
      return (
        <IonCol size="1">
            <IonButton fill="clear" color="light" onClick={() => logOut()} >
              <IonIcon slot="start" size="large" icon={logOutOutline} />
            </IonButton>
        </IonCol>
      )
    }
  }

  const reloadCarData = (e) => {
    setTimeout(() => {
      if(blocks >= totalCoches){
        e.target.disabled = true;
      } else {
        setBlocks(blocks + 5);
        e.target.complete();
      }
    }, 500);
  }

  return (
    <IonPage>
      <IonHeader className="web-header ion-no-border">
        <IonToolbar className="web-header ion-no-border">
          <IonGrid>
            <IonRow>
              { isPlatform('desktop') ? <IonCol size="12" sizeMd="4" className="ion-text-center ion-margin-vertical">
                <IonTitle onclick={() => clickLogin()}>Autos Goferan</IonTitle>
              </IonCol> : null}
              <IonCol size={isAuthenticated ? "10" : "12"} sizeMd={isAuthenticated ? "7" : "8"} className="ion-align-items-center ion-justify-content-center">
                <IonSearchbar
                  type="text"
                  placeholder="Busca un coche"
                  value={searchText}
                  onIonChange={(e) => setSearchText(e.target.value)}
                  showCancelButton="focus"
                  clearInput
                />
              </IonCol>
              { iconLogOut(isAuthenticated) }
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {
          isLoading
          ? <CarSpinner/>
          : <CarList name={name} isAuthenticated={isAuthenticated} setModalShow={setModalShow} carData={coches} doRefresh={doRefresh} filters={filters} />
        }
        <IonInfiniteScroll threshold="100px" className="carInfiniteScroll" onIonInfinite={(e) => reloadCarData(e)}>
          <IonInfiniteScrollContent
            loadingSpinner="lines"
          />
        </IonInfiniteScroll>
        { createOrFilterButton(isAuthenticated) }
      </IonContent>
      <DeleteModal isModalShow={modalShow.active} setModalShow={setModalShow} itemId={modalShow.itemId} isItem="coche" />
      <CarFilterModal isModalShow={modalShowFilter} setModalShow={setModalShowFilter} carData={coches} filter={filters} setFilter={setFilters} />
    </IonPage>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.userReducer.isAuthenticated,
    coches: state.cochesReducer.coches,
    isLoading: state.cochesReducer.isLoading,
    totalCoches: state.cochesReducer.totalCoches,
    marcasCoches: state.cochesReducer.marcasCoches
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ listCoches, listTotalCoches, listCochesMarcas, logOut }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);