import * as ActionTypes from './actions';

const preLoadState = {
  coches: [],
  marcasCoches: [],
  totalCoches: 0,
  isLoading: true
}

const cochesReducer = (state = preLoadState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_TOTAL_COCHES:
      return {
        ...state,
        totalCoches: action.total
      }
    case ActionTypes.LOAD_COCHES_MARCAS:
      return {
        ...state,
        marcasCoches: action.marcas,
      }
    case ActionTypes.LOAD_COCHES:
      return {
        ...state,
        coches: action.coches,
        isLoading: false
      }     
    case ActionTypes.EDIT_COCHE:
      return {
        ...state,
        coches: state.coches.map(coche => coche.id === action.coche.id ? { ...action.coche } : coche)
      }
    case ActionTypes.CREATE_COCHE:
      return {
        ...state,
        coches: [...state.coches, action.coche]
      }
    case ActionTypes.DELETE_COCHE:
      return {
        ...state,
        coches: state.coches.filter(coche => coche.id !== action.coche_id)
      }
    default:
      return state;
  }
}

export default cochesReducer;
