import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonTitle,
  IonSlides,
  IonSlide,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from '@ionic/react';

import './styles/CarDetail.scss';
import shareFunctions from './functions/shareFunctions';

import moment from 'moment';
import CarActionSheet from './CarActionSheet';
import CarBadges from './CarBadges';
import ImageZoom from 'react-medium-image-zoom';
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { detailCoche } from '../store/coches/actions';
import NotFound from './NotFound';

const CarDetail = ({ coches, detailCoche }) => {

  const { id } = useParams();

  const [coche, setCoche] = useState({
    caracteristicas: { transmision: '', combustible: '', cvs: '', fecha_matriculacion: '', kms: '', puertas: '' },
    fotos: [{
      formats: {
        small: {
          url: ''
        }
      }
    }],
    marca: '',
    modelo: '',
    precio: 0,
    reservado: false
  });
  const [firstLoad, setFirstLoad] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const fullPath = window.location.href;

  useEffect(() => {
    if (firstLoad) {
      const getCarDetail = async (coche_id) => {
        const coche = await detailCoche(coche_id);
        setCoche(coche);
      };

      if (coches.length > 0) {
        const coche = coches.filter((coche) => {
          return coche.id === id;
        })[0];
        setCoche(coche);
      } else {
        getCarDetail(id);
      }
      setFirstLoad(false);
    }
    // eslint-disable-next-line
  }, []);

  const slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  const renderCarImages = (coche) => {
    return coche.fotos.map((foto, idx) => (
      <IonSlide key={idx} className="img_slider">
        {/* <TransformWrapper>
          <TransformComponent> */}
            <ImageZoom image={{
              alt: `${coche.marca} + ${coche.modelo}`,
              src: `${foto.url}`
            }} />
          {/* </TransformComponent>
        </TransformWrapper> */}
      </IonSlide>
    ));
  };

  return (
    coche ?
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{coche.marca} {coche.modelo}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="car-detail-grid">
          <IonRow className="ion-no-padding">
            <IonCol size="12" sizeMd="6" className="ion-no-padding">
              <IonSlides pager={true} options={slideOpts}>
                {renderCarImages(coche)}
              </IonSlides>
            </IonCol>
            <IonCol className="car-detail-box-info">
              <IonRow>
                <IonRow>
                  <IonCol size="12">
                    <IonTitle className="car-detail-name">{coche.marca} - {coche.modelo}</IonTitle>
                  </IonCol>
                  <IonCol size="12">
                    <IonTitle className="car-detail-price" color="danger">{coche.precio} €</IonTitle>
                  </IonCol>
                  <IonCol size="12" className="ion-no-padding">
                    <CarBadges detallesCoche={coche} />
                  </IonCol>
                </IonRow>
              </IonRow>
              <IonRow className="ion-align-items-end">
                <IonCol>
                  <IonText>
                    <p>{coche.descripcion}</p>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="car-detail-second-box">
            <IonCol size="12" sizeMd="6">
              <IonGrid className="car-detail-table-content">
                <IonRow>
                  <IonCol size="6" className="ion-padding">
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Marca</IonCol>
                      <IonCol className="car-detail-table-values">{coche.marca}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Modelo</IonCol>
                      <IonCol className="car-detail-table-values">{coche.modelo}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Año</IonCol>
                      <IonCol className="car-detail-table-values">{moment(coche.caracteristicas.fecha_matriculacion, "YYYY-MM-DD").format('YYYY')}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Kms</IonCol>
                      <IonCol className="car-detail-table-values">{coche.caracteristicas.kms}</IonCol>
                    </IonRow>
                  </IonCol>
                  <div className="car-detail-table-separator"></div>
                  <IonCol className="ion-padding">
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Puertas</IonCol>
                      <IonCol className="car-detail-table-values">{coche.caracteristicas.puertas}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Combust</IonCol>
                      <IonCol className="car-detail-table-values">{coche.caracteristicas.combustible}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Transm</IonCol>
                      <IonCol className="car-detail-table-values">{coche.caracteristicas.transmision}</IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="car-detail-table-titles">Cvs</IonCol>
                      <IonCol className="car-detail-table-values">{coche.caracteristicas.cvs}</IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6" className="car-detail-second-box-buttons ion-float-end">
              <IonButton color="danger" onClick={() => setShowAlert(true)} expand="block">más información</IonButton>
              <IonButton className="ion-margin-top" color="medium" onClick={() => shareFunctions.shareCar(fullPath)} expand="block">compartir</IonButton>
              <CarActionSheet isActive={showAlert} setIsActive={setShowAlert} cancelAction={() => { }} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
    : 
      <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/" />
        </IonButtons>
        <IonTitle>No encontrado</IonTitle>
      </IonToolbar>
      </IonHeader>
      <IonContent>
        <NotFound />
      </IonContent>
      </IonPage>
  );
};

function mapStateToProps(state) {
  return {
    coches: state.cochesReducer.coches
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ detailCoche }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CarDetail));
