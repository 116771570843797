import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonToggle
} from "@ionic/react";
import CarFotoEditor from "../components/CarFotoEditor";

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCoche, listCochesMarcas } from '../store/coches/actions';

import CarBrands from "../components/CarBrands";

import "../components/styles/CarDetail.scss";

const NewCar = ({ createCoche, listCochesMarcas }) => {

  const history = useHistory();

  const [newDatosCoche, setNewDatosCoche] = useState({
    caracteristicas: {
      transmision: "",
      combustible: "",
      cvs: null,
      kms: null,
      puertas: null,
      fecha_matriculacion: "",
    },
    precio: null,
    reservado: false,
    fotos: [],
    marca: "",
    modelo: "",
  });
  const [marcasCoches, setMarcasCoches] = useState([]);
  const [ newFotosCoche ] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const getCarBrands = async () => {
    const marcas = await listCochesMarcas();
    setMarcasCoches(marcas);
  }

  useEffect(() => {
    if(marcasCoches.length <= 0){
      getCarBrands();
    }
    updateCarFotos();
  }, [newFotosCoche]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateCarFotos = () => {
    const newArrayFotos = [];
    newFotosCoche.forEach(img => {
      newArrayFotos.push(JSON.parse(img.response.body.fileName)[0]);
    });
    setNewDatosCoche({ ...newDatosCoche, fotos: newArrayFotos });
  }

  const onSubmitLogin = (event) => {
    event.preventDefault();
    createCoche(newDatosCoche, history);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/' />
          </IonButtons>
          <IonTitle>
            Crear coche
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={onSubmitLogin}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Marca</IonLabel>
                  <IonSelect
                      required
                      value={newDatosCoche.marca}
                      okText="Aceptar"
                      cancelText="Cerrar"
                      onIonChange={(e) => {
                        setNewDatosCoche({
                          ...newDatosCoche,
                          marca: e.detail.value
                        });
                      }}
                    >
                     { marcasCoches ? <CarBrands marcas={marcasCoches} /> : null}
                    </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Modelo</IonLabel>
                  <IonInput
                    required
                    type="text"
                    value={newDatosCoche.modelo.toUpperCase()}
                    onIonInput={(e) => setNewDatosCoche({ ...newDatosCoche, modelo: e.target.value.toUpperCase() })}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Año</IonLabel>
                  <IonInput
                    required
                    value={newDatosCoche.caracteristicas.fecha_matriculacion}
                    type="number"
                    onIonInput={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        caracteristicas: {
                          ...newDatosCoche.caracteristicas,
                          fecha_matriculacion: e.target.value,
                        },
                      })
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Cvs</IonLabel>
                  <IonInput
                    required
                    type="number"
                    min={0}
                    max={1000}
                    value={newDatosCoche.caracteristicas.cvs}
                    onIonInput={(e) => setNewDatosCoche({
                      ...newDatosCoche,
                      caracteristicas: { ...newDatosCoche.caracteristicas, cvs: e.target.value }
                    })}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel>Kms</IonLabel>
                  <IonInput
                    required
                    type="number"
                    min={0}
                    max={1500000}
                    value={newDatosCoche.caracteristicas.kms}
                    onIonInput={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        caracteristicas: {
                          ...newDatosCoche.caracteristicas,
                          kms: e.target.value,
                        },
                      })
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel>Puertas</IonLabel>
                  <IonInput
                    required
                    type="number"
                    min={0}
                    max={10}
                    value={newDatosCoche.caracteristicas.puertas}
                    onIonInput={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        caracteristicas: {
                          ...newDatosCoche.caracteristicas,
                          puertas: e.target.value,
                        },
                      })
                    }
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonItem>
                  <IonLabel>Transmisión</IonLabel>
                  <IonSelect
                    required
                    value={newDatosCoche.caracteristicas.transmision}
                    placeholder="Selecciona"
                    onIonChange={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        caracteristicas: {
                          ...newDatosCoche.caracteristicas,
                          transmision: e.detail.value,
                        }
                      })
                    }
                  >
                    <IonSelectOption value="automatico">Automático</IonSelectOption>
                    <IonSelectOption value="manual">Manual</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonItem>
                  <IonLabel>Combustible</IonLabel>
                  <IonSelect
                    required
                    value={newDatosCoche.caracteristicas.combustible}
                    onIonChange={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        caracteristicas: {
                          ...newDatosCoche.caracteristicas,
                          combustible: e.detail.value,
                        }
                      })
                    }
                    placeholder="Selecciona"
                  >
                    <IonSelectOption value="diesel">Diesel</IonSelectOption>
                    <IonSelectOption value="gasolina">Gasolina</IonSelectOption>
                    <IonSelectOption value="glp">GLP</IonSelectOption>
                    <IonSelectOption value="electrico">Eléctrico</IonSelectOption>
                    <IonSelectOption value="hibrido">Híbrido</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonItem>
                  <IonLabel>Precio</IonLabel>
                  <IonInput
                    required
                    type="number"
                    min={0}
                    max={100000}
                    value={newDatosCoche.precio}
                    onIonInput={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        precio: e.target.value
                      })
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel color="danger">Reservado</IonLabel>
                  <IonToggle
                    required
                    checked={newDatosCoche.reservado}
                    onIonChange={e => setNewDatosCoche({
                      ...newDatosCoche,
                      reservado: e.detail.checked
                    })} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem lines="none" className="car-description-item">
                  <IonLabel className="car-description" position="floating">
                    Descripción
                  </IonLabel>
                  <IonTextarea
                    required
                    value={newDatosCoche.descripcion}
                    rows={3}
                    onIonInput={(e) =>
                      setNewDatosCoche({
                        ...newDatosCoche,
                        descripcion: e.target.value
                      })
                    }
                  ></IonTextarea>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            color="medium"
            expand="block"
            className="ion-margin"
            onClick={() => setModalShow(true)}
          >Añadir imágenes</IonButton>
          <IonButton className="ion-margin" type="submit" expand="block">
            Subir coche
          </IonButton>
        </form>
        <CarFotoEditor
          isModalShow={modalShow}
          setModalShow={setModalShow}
          editDatosCoche={newDatosCoche}
          setEditCoche={setNewDatosCoche}
        />
      </IonContent>
    </IonPage >
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createCoche, listCochesMarcas }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(NewCar));
