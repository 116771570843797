import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    IonContent,
    IonHeader,
    IonModal,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonText,
    IonCol,
    IonRow
} from '@ionic/react';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteCoche } from '../store/coches/actions';
import ApiCoches from '../api/ApiCoches';
import './styles/DeleteModal.scss';
import { closeOutline } from 'ionicons/icons';

const api = new ApiCoches();

const DeleteModal = ({ isModalShow, setModalShow, isItem, itemId, deleteCoche }) => {

    const history = useHistory();

    const deleteAction = (itemId) => {
        switch (isItem) {
            case "coche":
                deleteCoche(itemId);
                break;
            case "foto":
                api.deleteFoto(itemId);
                break;
            default:
                break;
        }
        setModalShow({...isModalShow, active: false});
        history.push('/');
    }

    return (
            <IonModal
                isOpen={isModalShow}
                className="car-delete-modal"
                onDidDismiss={() => setModalShow({ ...isModalShow, active: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Eliminar</IonTitle>
                    </IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" onClick={() => setModalShow({ ...isModalShow, active: false })}>
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                    </IonButtons>
                </IonHeader>
                <IonContent className="ion-margin ion-padding ion-text-center">
                    <IonRow>
                        <IonCol>
                            <IonText>¿Estás seguro de que quieres eliminar?</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" color="danger" onClick={() => deleteAction(itemId)}>Eliminar</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton expand="block" color="medium" onClick={() => setModalShow({ ...isModalShow, active: false })}>Cerrar</IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ deleteCoche }, dispatch)
}

export default connect(
    null,
    mapDispatchToProps
)(DeleteModal);
