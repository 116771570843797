import * as ActionTypes from './actions';
import { PURGE } from 'redux-persist';

const preLoadState = {}

const userAuthReducer = (state = preLoadState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USER:
      return {
        jwt_token: action.user.jwt
      }
    case PURGE:
      return preLoadState;
    default:
      return state;
  }
}

export default userAuthReducer;
